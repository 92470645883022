import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { rect, qs, on, round, lerp } from 'martha'

gsap.registerPlugin(ScrollToPlugin)

export default function raf(app) {
  let target = 0
  let current = 0
  let ease = 0.15

  gsap.ticker.fps(-1)
  gsap.ticker.add(tick)

  on(window, 'scroll', scroll)
  app.on('scroll:to', scrollTo)
  app.on('scroll:reset', reset)
  app.on('resize:reset', resize)

  function tick() {
    current =
      app.getState().ww >= 768
        ? round(lerp(current, target, ease), 100)
        : target
    app.emit('tick', { scroll: current })
  }

  function scroll() {
    target = window.scrollY
  }

  function scrollTo(_, target) {
    const offset = target.offsetTop
    const padding = rect(qs('[data-scroll-padding-top]'))?.bottom ?? 0
    const y = offset - padding
    const temp = { y: current }

    gsap.to(temp, {
      y: y < 0 ? 0 : y,
      duration: 0.5,
      ease: 'expo.inOut',
      onStart() {
        app.hydrate({ isAutoScrolling: true })
      },
      onUpdate() {
        window.scroll(0, temp.y)
        target = current = temp.y
      },
      onComplete() {
        app.hydrate({ isAutoScrolling: false })
      },
    })
  }

  function reset() {
    target = current = 0
  }

  function resize() {
    current = target
  }
}
