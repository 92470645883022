import { component } from 'picoapp'
import { on, add, remove } from 'martha'

export default component((node) => {
  const cx = ['bg-yellow', 'bg-red', 'bg-theme-blue', 'bg-pink', 'bg-orange']

  let id = null
  let delay = 750 / cx.length

  let offEnter = on(node, 'mouseenter', () => {
    step(0)

    function step(i) {
      clear()
      add(node, cx[i % cx.length])
      id = setTimeout(() => step(i + 1), delay)
    }
  })

  let offLeave = on(node, 'mouseleave', () => {
    id && clearTimeout(id)
    id = null
    clear()
    add(node, 'bg-orange')
  })

  function clear() {
    remove(node, cx.join(' '))
  }

  return () => {
    offEnter()
    offLeave()
  }
})
