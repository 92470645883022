import { component } from 'picoapp'
import { noop, on, remove } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let offLoad = noop
  let offEnd = noop

  let refs = choozy(node)

  let resizeObserver = new ResizeObserver((entries) => {
    const width = entries[0].contentRect.width
    refs.img.sizes = `${width}px`
  })

  resizeObserver.observe(node)

  ctx.on('enter:completed', () => {
    offLoad = on(refs.img, 'load', () => {
      offLoad()
      offLoad = noop

      if (refs?.lqip) {
        offEnd = on(refs.img, 'transitionend', () => {
          offEnd()
          offEnd = noop
          refs.lqip.remove()
        })
      }

      remove(refs.img, 'opacity-0')
    })

    refs.img.srcset = refs.img.dataset.srcset
    refs.img.removeAttribute('data-srcset')
  })

  return () => {
    offLoad()
    offEnd()

    resizeObserver.disconnect()
  }
})
