import Highway from '@dogstudio/highway'
import gsap from 'gsap'
import { has, qsa, add } from 'martha'

class Fade extends Highway.Transition {
  in({ from, to, done }) {
    let content = qsa('[data-menu-content]', to)

    window.scroll(0, 0)

    gsap.set(content, { autoAlpha: 0, x: -5 })
    gsap.set(to, { autoAlpha: 1 })

    from.remove()

    gsap.to(content, {
      x: 0,
      autoAlpha: 1,
      duration: 0.5,
      clearProps: 'all',
      onComplete: done,
    })
  }

  out({ from, done }) {
    let content = qsa('[data-menu-content]', from)
    let cx = 'scale-x-0'
    let lines = qsa('.ch-menu-link, .ch-mobile-menu-link', from)
      .map((l) => l.firstElementChild)
      .filter((l) => !has(l, cx))

    if (lines.length) {
      add(lines, cx)
    }

    gsap.to(content, {
      x: 5,
      autoAlpha: 0,
      duration: 0.5,
      onComplete: done,
    })
  }
}

export default Fade
