import Highway from '@dogstudio/highway'
import gsap from 'gsap'
import choozy from 'choozy'
import app from '../app'
import { add, qs, rect } from 'martha'

class LandingToCH extends Highway.Transition {
  in({ from, to, done }) {
    from.refs = {
      mc: choozy(from, 'js:mc-'),
      ch: choozy(from, 'js:ch-'),
    }

    to.refs = choozy(to)

    let duration = 0.5
    let delay = duration

    let { mc, ch } = from.refs
    let fromMc = qs('.js-face', mc.face).parentNode
    let fromFriend = qs('.js-face', ch.friend).parentNode
    let toFriend = to.refs.face[0].parentNode
    let fromFace = qs('.js-face', ch.face).parentNode
    let toFace = to.refs.face[1].parentNode

    fromFriend.rect = rect(fromFriend)
    toFriend.rect = rect(toFriend)
    fromFace.rect = rect(fromFace)
    toFace.rect = rect(toFace)

    let fromScale = toFriend.rect.height / fromFriend.rect.height
    let toScale = fromFriend.rect.height / toFriend.rect.height
    let fromFriendX = toFriend.rect.x - fromFriend.rect.x
    let toFriendX = -fromFriendX
    let fromFaceX = toFace.rect.x - fromFace.rect.x
    let toFaceX = -fromFaceX

    let tl = gsap.timeline({
      defaults: { duration, ease: 'expo' },
      paused: true,
      onComplete() {
        from.remove()
        done()
      },
    })

    tl.set(from, { zIndex: 1 })
      .set(ch.outer, { zIndex: 1 })
      .set([to.refs.header, to.refs.face], { autoAlpha: 0 })
      .set(to, { autoAlpha: 1 })
      .set(ch.bg, { autoAlpha: 0 })
      .to(
        [ch.links, ch.logo],
        {
          autoAlpha: 0,
          duration: duration / 2,
        },
        0,
      )

    let { face, leftEyeWrap, rightEyeWrap, noseWrap, mouthWrap } = choozy(
      ch.face,
    )
    let parts = [leftEyeWrap, rightEyeWrap, noseWrap, mouthWrap]

    tl.to(
      ch.bits,
      {
        autoAlpha: 0,
        duration: 0,
        stagger: {
          from: 'random',
          amount: duration / 2,
        },
      },
      0,
    )
      .set(parts, { scale: 0 })
      .set(face[0], { alpha: 1 })
      .to(
        parts,
        {
          scale: 1,
          stagger: {
            amount: duration / 2,
          },
        },
        duration / 2,
      )

    tl.set(
      [fromMc, fromFriend, fromFace, toFriend, toFace],
      {
        transformOrigin: 'left',
      },
      delay,
    )
      .from(
        [toFriend, toFace],
        {
          scale: toScale,
          x: gsap.utils.wrap([toFriendX, toFaceX]),
          ease: 'expo.inOut',
        },
        delay,
      )
      .to(
        [fromMc, fromFriend, fromFace],
        {
          scale: fromScale,
          x: gsap.utils.wrap([fromFriendX, fromFriendX, fromFaceX]),
          ease: 'expo.inOut',
        },
        delay,
      )
      .to(
        [ch.outer, ch.inner],
        {
          transform: gsap.utils.wrap(['translateX(0%)', 'translateX(0%)']),
          ease: 'expo.inOut',
        },
        delay,
      )
      .to(
        [mc.outer, mc.inner],
        {
          transform: gsap.utils.wrap(['translateX(-100%)', 'translateX(100%)']),
          ease: 'expo.inOut',
        },
        delay,
      )
      .to(
        to.refs.header,
        {
          autoAlpha: 1,
          ease: 'expo.inOut',
        },
        delay,
      )
      .from(
        to.refs.tagline,
        {
          autoAlpha: 0,
          yPercent: 300,
          rotation: 15,
          ease: 'expo.inOut',
        },
        delay,
      )
      .add(() => app.emit('face:unmount'))
      .set(to.refs.face, { autoAlpha: 1 })
      .restart()
  }

  out({ done }) {
    done()
  }
}

export default LandingToCH
