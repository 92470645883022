import { component } from 'picoapp'
import { on, qs, qsa, rect, add, remove } from 'martha'

export default component((node, ctx) => {
  const href = node.href.split('#').pop()
  const target = qs(`#${href}`)
  const links = qsa('a[href^="#"]', node.closest('nav'))
  const cx =
    node.dataset.theme === 'middle-child'
      ? 'text-green'
      : '!scale-x-100 !origin-left'

  let isActive = false

  let offClick = on(node, 'click', (ev) => {
    ev.preventDefault()

    if (target) {
      setActiveLinks(target, links)
      ctx.emit('scroll:to', null, target)
    }
  })

  ctx.on('tick', ({ isAutoScrolling, wh }) => {
    if (isAutoScrolling) return

    const bounds = rect(target)
    const threshold = wh * 0.3

    if (bounds.top < threshold && bounds.bottom > threshold) {
      if (isActive) return
      isActive = true
      target && setActiveLinks(target, links)
    } else {
      isActive = false
    }
  })

  function setActiveLinks(target, links) {
    const activeLinks = links.filter(
      (l) => l.getAttribute('href').slice(1) === target.id,
    )

    if (node.dataset.theme === 'middle-child') {
      remove(links, cx)
      add(activeLinks, cx)
    } else {
      remove(
        links.map((l) => l.firstElementChild),
        cx,
      )
      add(
        activeLinks.map((l) => l.firstElementChild),
        cx,
      )
    }
  }

  return () => {
    offClick()
  }
})
