import { component } from 'picoapp'
import choozy from 'choozy'
import { qsa, on, add, remove } from 'martha'
import gsap from 'gsap'
import allToArray from '../lib/allToArray'

export default component((node, ctx) => {
  let { links } = choozy(node, 'js:landing-')
  let elsToInvert = qsa('[data-invert]', node)
  let tl = gsap.timeline({
    defaults: { duration: 0.4, ease: 'quint' },
    paused: true,
  })

  let offEnter = on(links, 'mouseenter', ({ type, currentTarget }) => {
    let index = links.findIndex((link) => link === currentTarget)
    update(type, index)
  })

  let offLeave = on(links, 'mouseleave', ({ type, currentTarget }) => {
    let index = links.findIndex((link) => link === currentTarget)
    update(type, index)
  })

  function update(type, index) {
    links.forEach((link, i) => {
      let {
        face,
        leftEyeWrap,
        rightEyeWrap,
        noseWrap,
        mouthWrap,
        mouth,
        mouthInner,
      } = allToArray(choozy(link))

      let isHoveredLink = i === index
      let isOtherLink = i !== index
      let isMc = index === 0
      let isCh = index === 1
      let isEnter = type === 'mouseenter'
      let isLeave = type === 'mouseleave'

      if (isHoveredLink) {
        if (isMc) {
          if (isEnter) {
            tl.clear()
              .to(
                [leftEyeWrap, rightEyeWrap, noseWrap, mouthWrap],
                {
                  x: gsap.utils.wrap(['-7em', '9em', '3em', 0]),
                  y: gsap.utils.wrap(['-8em', '0.5em', '-6em', 0]),
                  rotation: gsap.utils.wrap([0, 0, 30, -18]),
                },
                0,
              )
              .restart()
          }

          if (isLeave) {
            tl.clear()
              .to(
                [leftEyeWrap, rightEyeWrap, noseWrap, mouthWrap],
                {
                  x: 0,
                  y: 0,
                  rotation: 0,
                },
                0,
              )
              .restart()
          }
        }

        if (isCh) {
          if (isEnter) {
            if (ctx.getState().isDayMode) {
              elsToInvert.forEach((el) => {
                const [classToRemove, classToAdd] = el.dataset.invert.split(',')
                remove(el, classToRemove)
                add(el, classToAdd)
              })
            }
            add(face[0], 'opacity-0')
            remove(face[1], 'opacity-0')
          }

          if (isLeave) {
            if (ctx.getState().isDayMode) {
              elsToInvert.forEach((el) => {
                const [classToAdd, classToRemove] = el.dataset.invert.split(',')
                remove(el, classToRemove)
                add(el, classToAdd)
              })
            }
            remove(face[0], 'opacity-0')
            add(face[1], 'opacity-0')
          }
        }
      }

      if (isOtherLink) {
        if (isEnter) {
          remove(mouth, 'overflow-hidden')
          add(mouthInner, `translate-y-[4.5em]`)
          gsap.set(mouthInner, {
            width: '16em',
            height: '16em',
          })
        }

        if (isLeave) {
          add(mouth, 'overflow-hidden')
          remove(mouthInner, `translate-y-[4.5em]`)
          gsap.set(mouthInner, {
            width: null,
            height: null,
          })
        }
      }
    })
  }

  return () => {
    tl.clear()
    offEnter()
    offLeave()
  }
})
