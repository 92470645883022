import Highway from '@dogstudio/highway'
import gsap from 'gsap'
import choozy from 'choozy'
import app from '../app'

class LandingToMC extends Highway.Transition {
  in({ from, to, done }) {
    from.refs = {
      mc: choozy(from, 'js:mc-'),
      ch: choozy(from, 'js:ch-'),
    }

    to.refs = choozy(to)

    let { mc, ch } = from.refs

    let duration = 0.5
    let delay = duration

    let tl = gsap.timeline({
      defaults: { duration, ease: 'expo' },
      paused: true,
      onComplete() {
        from.remove()
        done()
      },
    })

    tl.set(from, { zIndex: 1 })
      .set([to.refs.header, to.refs.face], { autoAlpha: 0 })
      .set(to, { autoAlpha: 1 })
      .set(mc.bg, { autoAlpha: 0 })
      .to([mc.links, mc.logo], { autoAlpha: 0 }, 0)

    let { leftEyeWrap, rightEyeWrap, noseWrap, mouthWrap } = choozy(mc.face)

    tl.to(
      [leftEyeWrap, rightEyeWrap],
      {
        x: gsap.utils.wrap(['-8em', '10em']),
      },
      0,
    )
      .to(
        [leftEyeWrap, rightEyeWrap],
        {
          x: 0,
          y: 0,
          ease: 'expo.inOut',
        },
        delay,
      )
      .to(noseWrap, { y: '-18em' }, 0)
      .to(
        noseWrap,
        {
          y: 0,
          ease: 'expo.inOut',
        },
        delay,
      )
      .to(
        noseWrap,
        {
          x: 0,
          rotation: 360,
          duration: duration * 2,
        },
        0,
      )
      .to(
        mouthWrap,
        {
          rotation: -23,
        },
        0,
      )
      .to(
        mouthWrap,
        {
          rotation: 0,
          ease: 'expo.inOut',
        },
        delay,
      )

    tl.set(mc.outer, { autoAlpha: 0 })
      .set(to.refs.face, { autoAlpha: 1 })
      .add(() => app.emit('face:unmount'))
      .to(
        [ch.outer, ch.inner],
        {
          transform: gsap.utils.wrap(['translateX(100%)', 'translateX(-75%)']),
          ease: 'expo.inOut',
        },
        delay,
      )
      .to(
        to.refs.header,
        {
          autoAlpha: 1,
          ease: 'expo.inOut',
        },
        delay,
      )
      .from(
        to.refs.tagline,
        {
          autoAlpha: 0,
          yPercent: 300,
          rotation: 15,
          ease: 'expo.inOut',
        },
        delay,
      )
      .restart()
  }

  out({ done }) {
    done()
  }
}

export default LandingToMC
