import { picoapp } from 'picoapp'
import { size, qs } from 'martha'
import isDayMode from './lib/isDayMode'

import wonkyHover from './components/wonkyHover'
import cycleHover from './components/cycleHover'
import orderDropdown from './components/orderDropdown'
import orderPopover from './components/orderPopover'
import orderNowButton from './components/orderNowButton'
import face from './components/face'
import landing from './components/landing'
import scroll from './components/scroll'
import sticky from './components/sticky'
import lazy from './components/lazy'
import carousel from './components/carousel'
import hoagie from './components/hoagie'
import header from './components/header'
import anchor from './components/anchor'
import orderReserveModal from './components/orderReserveModal'

const components = {
  wonkyHover,
  cycleHover,
  orderDropdown,
  orderPopover,
  orderNowButton,
  face,
  landing,
  scroll,
  sticky,
  lazy,
  carousel,
  hoagie,
  header,
  anchor,
  orderReserveModal,
}

const sizes = size()
const state = {
  ...sizes,
  mx: sizes.ww / 2,
  my: sizes.wh / 2,
  isDayMode: isDayMode(),
  isAutoScrolling: false,
  isOpen: false,
  orderReserveModalIsOpen: false,
  dom: {
    html: document.documentElement,
    body: document.body,
    scrollProxy: qs('.js-scroll-proxy'),
  },
  fonts: [
    { family: 'GT Walsheim' },
    { family: 'GT Walsheim', options: { weight: 300 } },
    { family: 'GT Walsheim', options: { weight: 300, style: 'italic' } },
    { family: 'Souvenir' },
    { family: 'Alte Haas Grotesk', options: { weight: 400 } },
    { family: 'Alte Haas Grotesk', options: { weight: 700 } },
  ],
  screens: {
    '2xs': 375,
    xs: 400,
    s: 650,
    m: 768,
    l: 1024,
    xl: 1281,
    '2xl': 1536,
  },
}

export default picoapp(components, state)
