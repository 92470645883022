import { component } from 'picoapp'
import { qs, clamp, norm } from 'martha'
import gsap from 'gsap'
import quintOut from 'eases/quint-out'

export default component((node, ctx) => {
  let view = node.closest('[data-router-view]')
  let footer = qs('[data-footer]', view)
  let start = null
  let end = null

  ctx.on('resize:reset', ({ wh }) => {
    end = footer.offsetTop - wh
    start = end - 100
  })

  ctx.on('tick', ({ scroll }) => {
    gsap.set(node, {
      autoAlpha: quintOut(clamp(1 - norm(scroll, start, end), 0, 1)),
    })
  })
})
