import { component } from 'picoapp'
import { add, remove, index, on, rect, noop } from 'martha'
import choozy from 'choozy'
import gsap from 'gsap'

import createCarousel from 'embla-carousel'

export default component((node, ctx) => {
  let { itemWrap, captionWraps, next, prev, cursor, count, btns } = choozy(node)

  const embla = createCarousel(itemWrap, {
    loop: true,
    duration: 0,
  })

  cursor.remove()
  node.closest('[data-scroll]').append(cursor)

  let evs = [
    on(prev, 'click', () => embla.scrollPrev()),
    on(next, 'click', () => embla.scrollNext()),
    btns
      ? on(btns, 'click', ({ currentTarget: t }) => embla.scrollTo(index(t)))
      : noop,
  ]

  embla.on('select', () => {
    const i = embla.selectedScrollSnap()
    count.textContent = i + 1

    if (btns) {
      btns.forEach((btn, j) =>
        j === i
          ? add(btn, 'bg-theme-primary')
          : remove(btn, 'bg-theme-primary'),
      )
    }
  })

  ctx.on('resize', () => {
    gsap.set(cursor, { x: 0, y: 0 })
    cursor.rect = rect(cursor)

    if (captionWraps) {
      gsap.set(captionWraps, {
        height: Math.max(...captionWraps.map((el) => rect(el).height)),
      })
    }
  })

  ctx.on('tick', ({ mx, my, scroll }) => {
    itemWrap.rect = rect(itemWrap)

    const x = mx - cursor.rect.x
    const y = my - cursor.rect.y
    const autoAlpha =
      x > itemWrap.rect.left &&
      x < itemWrap.rect.right &&
      y > itemWrap.rect.top &&
      y < itemWrap.rect.bottom
        ? 1
        : 0

    gsap.set(cursor, { x, y: y + scroll, autoAlpha })
    gsap.set([next, prev], { cursor: autoAlpha ? 'none' : 'auto' })
  })

  return () => {
    embla.destroy()
    evs.map((off) => off())
  }
})
