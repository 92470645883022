import choozy from 'choozy'
import { noop, on } from 'martha'
import { component } from 'picoapp'
import gsap from 'gsap'
import onClickOutside from '../lib/onClickOutside'
import sqzbx from '../lib/sqzbx'

export default component((node, ctx) => {
  const { btn, arrow, backdrop, items, accordion } = choozy(node)

  let isShown = false
  let offKeyup = noop
  let tl = gsap.timeline({ paused: true })

  gsap.set(backdrop, { yPercent: -3, autoAlpha: 0 })
  gsap.set(items, { yPercent: -15, autoAlpha: 0 })

  const accordionInstance = sqzbx(accordion, {
    collapsible: true,
    multiple: false,
    autoResize: false,
  })

  ctx.on('resize', () => {
    accordionInstance.resize()
  })

  const unmountAccordion = accordionInstance.mount()

  let offClick = on(btn, 'click', () => (isShown ? hide() : show()))
  let offClickOutside = onClickOutside(node, () => {
    if (isShown) {
      hide()
    }
  })

  function show() {
    isShown = true

    btn.setAttribute('aria-expanded', 'true')

    offKeyup = on(window, 'keyup', ({ key }) => key === 'Escape' && hide())

    tl.clear()
      .to(
        arrow,
        {
          rotationX: -180,
          duration: 0.4,
          ease: 'power4',
        },
        0,
      )
      .to(
        backdrop,
        {
          yPercent: 0,
          autoAlpha: 1,
          duration: 0.7,
          ease: 'quint',
        },
        0,
      )
      .to(
        items,
        {
          yPercent: 0,
          autoAlpha: 1,
          stagger: 0.07,
          duration: 0.7,
          ease: 'quint',
        },
        0,
      )
      .restart()
  }

  function hide() {
    offKeyup()
    offKeyup = noop

    isShown = false

    btn.removeAttribute('aria-expanded')

    accordionInstance.reset()

    tl.clear()
      .to(
        arrow,
        {
          rotationX: 0,
          duration: 0.4,
          ease: 'expo',
        },
        0,
      )
      .to(
        backdrop,
        {
          yPercent: -3,
          autoAlpha: 0,
          duration: 0.7,
          ease: 'quint',
        },
        0,
      )
      .to(
        items,
        {
          yPercent: -15,
          autoAlpha: 0,
          duration: 0.4,
          stagger: 0.07,
          ease: 'expo',
        },
        0,
      )
      .restart()
  }

  return () => {
    offKeyup()
    offClick()
    offClickOutside()
    unmountAccordion()
  }
})
