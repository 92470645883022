import { on } from 'martha'

export default function onClickOutside(element, callback) {
  let off = on(document, 'click', (event) => {
    if (!element.contains(event.target)) {
      callback()
    }
  })

  return off
}
