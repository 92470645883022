import { component } from 'picoapp'
import gsap from 'gsap'
import { on, qsa } from 'martha'

export default component((node, ctx) => {
  let chars = qsa('.char', node)
  let y = parseInt(node?.dataset?.y ?? 8, 10)
  let rotation = parseInt(node?.dataset?.rotation ?? 4, 10)
  let duration = parseFloat(node?.dataset?.duration ?? 0.4, 10)

  let tl = gsap.timeline({
    paused: true,
    defaults: { duration, ease: 'power4' },
  })

  let offEnter = on(node, 'mouseenter', () => {
    tl.clear()
      .to(chars, {
        yPercent: gsap.utils.wrap([-y, y]),
        rotation: gsap.utils.wrap([rotation, -rotation]),
      })
      .restart()
  })

  let offLeave = on(node, 'mouseleave', () => {
    tl.clear()
      .to(chars, {
        yPercent: 0,
        rotation: 0,
      })
      .restart()
  })

  return () => {
    offEnter()
    offLeave()
  }
})
