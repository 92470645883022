import choozy from 'choozy'
import { noop, on } from 'martha'
import { component } from 'picoapp'
import gsap from 'gsap'
import sqzbx from '../lib/sqzbx'

export default component((node, ctx) => {
  const { btn, arrow, popover, backdrop, tray, item, accordion } = choozy(node)

  gsap.set(backdrop, { alpha: 0 })
  gsap.set([tray, item], { yPercent: 100 })

  let isShown = false
  let offKeyup = noop
  let tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: 0.7,
      ease: 'expo',
    },
  })

  const accordionInstance = sqzbx(accordion, {
    collapsible: true,
    multiple: false,
    autoResize: false,
  })

  ctx.on('resize', () => {
    accordionInstance.resize()
  })

  const unmountAccordion = accordionInstance.mount()

  let offClick = on([btn, backdrop], 'click', () => (isShown ? hide() : show()))

  function show() {
    isShown = true

    btn.setAttribute('aria-expanded', 'true')

    offKeyup = on(window, 'keyup', ({ key }) => key === 'Escape' && hide())

    tl.clear()
      .set(popover, { pointerEvents: 'auto' })
      .to(
        arrow,
        {
          rotationX: -180,
        },
        0,
      )
      .to(
        backdrop,
        {
          alpha: 0.75,
        },
        0,
      )
      .to(
        [tray, item],
        {
          yPercent: 0,
        },
        0,
      )
      .restart()
  }

  function hide() {
    offKeyup()
    offKeyup = noop

    isShown = false

    btn.removeAttribute('aria-expanded')

    accordionInstance.reset()

    tl.clear()
      .set(popover, { pointerEvents: 'none' })
      .to(
        arrow,
        {
          rotationX: 0,
        },
        0,
      )
      .to(
        backdrop,
        {
          alpha: 0,
        },
        0,
      )
      .to(
        [tray, item],
        {
          yPercent: 100,
        },
        0,
      )
      .restart()
  }

  return () => {
    offClick()
    unmountAccordion()
  }
})
