import { component } from 'picoapp'
import choozy from 'choozy'
import { on, lerp, map, round, clamp, add, remove } from 'martha'
import gsap from 'gsap'
import allToArray from '../lib/allToArray'
import poll from '../lib/poll'
import app from '../app'

export default component((node) => {
  let init = true

  let refs = allToArray(choozy(node))
  let ease = 0.15

  let lookProps = {
    right: 1,
    left: -1,
    center: 0,
  }

  let lookAlways = node.dataset.lookAlways === ''
  let lookDirection = lookProps[node.dataset.look]
  let lookX = 0.75
  let lookY = 0

  let blinkRate = parseInt(node.dataset.blinkRate) * 1000

  let cx = 0
  let cy = 0

  let offPoll = poll(
    blinkRate,
    (done) => {
      const className = 'blinky'
      ;[refs.leftEye[0], refs.rightEye[0]].forEach((el, i) => {
        let off = on(el, 'animationend', () => {
          off()
          remove(el, className)
          i === 1 && done()
        })
        add(el, className)
      })
    },
    false,
  )

  // attach event to app instead of component ctx to opt out of picoapp's automatic unmount behavior
  let offResize = app.on('resize', ({ ww }) => {
    if (!lookAlways) {
      lookX = ww >= 768 ? 0.75 : 0
    }
  })

  // attach event to app instead of component ctx to opt out of picoapp's automatic unmount behavior
  let offTick = app.on('tick', ({ ww, wh, mx, my }) => {
    if (ww < 768) {
      mx = ww * 0.5
      my = wh * 0.5
    }

    let tx = clamp(map(mx, 0, ww, -1, 1) + lookX * lookDirection, -1, 1)
    let ty = clamp(map(my, 0, wh, -1, 1) + lookY, -1, 1)

    if (init) {
      cx = tx
      cy = ty
      init = false
    } else {
      cx = lerp(cx, tx, ease)
      cy = lerp(cy, ty, ease)
    }

    const props = {
      face: {
        x: `${round(cx * 0.6)}em`,
        y: `${round(cy * 0.6)}em`,
        rotation: round(cx * cy * 8),
      },
      eyes: {
        x: `${round(clamp(cx * 1.6, -1.6, 1.6))}em`,
        y: `${round(clamp(cy * 0.8, -0.8, 0.8))}em`,
      },
      nose: {
        rotation: round(clamp(-cx * 10, -10, 10)),
      },
      mouth: {
        rotation: round(clamp(cx * 10, -10, 10)),
      },
    }

    Object.keys(props).map((key) => gsap.set(refs[key], props[key]))
  })

  function unmount() {
    offResize()
    offTick()
    offPoll()
  }

  return () => {
    if (node.closest('[data-defer-unmount]')) {
      let off = app.on('face:unmount', () => {
        off()
        unmount()
      })
    } else {
      unmount()
    }
  }
})
