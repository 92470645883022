import { qsa } from 'martha'

export default function invertTheme() {
  const view = qsa('[data-router-view]').pop()

  if (view.dataset.theme === 'clubhouse') {
    const primary = view.style.getPropertyValue('--theme-palette-primary')
    const secondary = view.style.getPropertyValue('--theme-palette-secondary')
    const blue = view.style.getPropertyValue('--theme-palette-blue')
    const altBlue = view.style.getPropertyValue('--theme-palette-alt-blue')

    view.style.setProperty('--theme-palette-primary', secondary)
    view.style.setProperty('--theme-palette-text', secondary)
    view.style.setProperty('--theme-palette-secondary', primary)
    view.style.setProperty('--theme-palette-blue', altBlue)
    view.style.setProperty('--theme-palette-alt-blue', blue)
  }
}
